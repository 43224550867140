import React, { use, useEffect, useState } from "react";

import BlockGravityForm from "@/components/blocks/miewtheme/BlockGravityForm/BlockGravityForm";
import GravityForm from "../GravityForm/GravityForm";
import { WpClientRequest } from "@/lib/wordpress/wpClientRequest";
import { WpRequest } from "@/lib/wordpress/wpRequest";
import { useGlobalContext } from "@/lib/globalContext";

const Newsletter = () => {
  const state = useGlobalContext();
  const [formData, setFormData] = useState<any>();
  const lang = state.lang;

  const formID = state?.dict?.custom?.newsletterForm || 2;

  useEffect(() => {
    WpClientRequest({
      post_type: `gf/v2/forms/${formID}/`,
      isContent: false,
      isForms: true,
      variables: {
        lang,
      },
    }).then((res) => {
      setFormData({ formId: formID, formData: res });
    });
  }, [lang]);

  return (
    <>
      {formData && <GravityForm {...formData} />}
    </>
  );
};

export default Newsletter;
