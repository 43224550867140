import { Button, Heading, variables } from 'oddsgate-ds';
import styled, { css } from 'styled-components';

import { IMenuMobile } from './Header.interface';

export const StyledHamburger = styled(Button) <IMenuMobile>`
  z-index: 6;

  & span {
    transform: none !important;
  }
  & i{
    position: static;
    opacity: 1;
    font-size: 12px;
    transform: none !important;
  }
  
  ${({ $active }) =>
    $active &&
    css`
    color: ${variables.colors.secondary50};
    background-color: ${variables.colors.primary50};

    & i{
      transform: rotate(180deg) !important;
    }
  `}
`

export const StyledMenu = styled.div<IMenuMobile>`
  position: absolute;
  top: 100%;
  right: 0;

  min-width: 24rem;
  height: 100dvh;
  max-height: 85dvh;

  margin-top: 12px;

  overflow-x: hidden;
  overflow-y: auto;

  opacity: ${props => props.$active ? 1 : 0};
  pointer-events: ${props => props.$active ? "auto" : "none"};
  
  z-index: 6;

  &> div{
    padding: 20px;
    background: ${variables.colors.secondary50};
    box-shadow: ${variables.shadows.sm};
    border-radius: ${variables.radius.md};

    opacity: ${props => props.$active ? 1 : 0};
    transform:  ${props => props.$active ? "rotate(0deg) translateY(0)" : "rotate(8deg) translateY(30px)"}; 
    overflow: hidden;
    transition: all 0.35s ease-in-out;

    &:first-child{
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    }

    & a:hover{
      color: ${variables.colors.primary50};
    }
  }

  @media only screen and (min-width: ${variables.responsiveMedia}) {
    height: auto;
  }
`;
export const StyledMenuOverlay = styled.div<IMenuMobile>`
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(44, 9, 41, 0.6);
  
  opacity: ${props => props.$active ? 1 : 0};
  pointer-events: ${props => props.$active ? "auto" : "none"};

  z-index: 5;
  transition: all 0.35s ease-in-out;
`;

export const StyledMenuPanelSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  & i{
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 2.2rem;
    height: 2.2rem;
    font-size: 12px;
    border-radius: 50%;
    color: ${variables.colors.secondary50};
    background-color: ${variables.colors.primary50};
  }

  &:hover{
    opacity: 0.6;
  }
`

export const StyledMenuPanel = styled.div<IMenuMobile>`
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  justify-content: space-between;

  padding: 20px;
  opacity: ${props => props.$active ? 1 : 0};
  visibility: ${props => props.$active ? "visible" : "hidden"};
  pointer-events: ${props => props.$active ? "auto" : "none"};
  background-color: ${variables.colors.secondary50};
`;

export const StyledMenuPanelClose = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  
  width: 2.8rem;
  height: 2.8rem;
  font-size: 16px;
  border-radius: 50%;
  color: ${variables.colors.secondary50};
  background-color: ${variables.colors.primary50};
  cursor: pointer;

  &:hover{
    opacity: 0.6;
  }
`;


export const StyledSearch = styled.div<IMenuMobile>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  color: white;
  background-color: ${variables.colors.primary50};
  transition: all 0.3s linear;
  z-index: 110;

  & > div{
    display: flex;
    flex-flow: column;
    justify-content: center;
    width: 100%;
    padding: 1rem 0;
    transform: translateY(-100%);
    transition: all 0.3s linear;
  }

  ${({ $active }) =>
    $active &&
    `
    opacity:1;
    visibility: visible;
    pointer-events: all;
    
    & > div{
      transform: translateY(0%);
    }
  `}
`;

export const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;

  padding: 8px 0;
  transition: all 0.3s linear;
  z-index: 100;

  ul{
    li{
      a{
        width: 100%;
        justify-content: space-between;
      }
    }
  }
  
  // .style-dark &{
  //   color: white;
  //   background-color: ${variables.colors.primary50};
  // }
  // .style-light &{
  //   color: ${variables.colors.primary50};
  //   background-color: white;
  // }

  & a{
    &:hover{
      color: ${variables.colors.primary50} !important;
    }
  }

  .header-absolute &{
    position: fixed;
    color:white;
    background-color: transparent;
  }
  
  .style-light &,
  &.scrolled{
    color:white;
    background: linear-gradient(0deg, rgba(44, 9, 41, 0) 0%, rgba(44, 9, 41, 0.6) 100%);
  }

  &.header-hidden{
    transform: translateY(-100%);
  }
`;


export const StyledPageTitle = styled(Heading)`
  position: relative;
  display: none;
  padding-left: 1.5rem;
  margin-left: 1.5rem;
  
  &:before{
    content: '|';
    position: absolute;
    top: 0;
    left: 0;
  }


  @media only screen and (min-width: 1024px) {
    display: inline-flex;
  }
`;