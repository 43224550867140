import Image from 'next/image';
import Link from 'next/link';
import { styled } from 'styled-components';
import { useGlobalContext } from '@/lib/globalContext';
import { variables } from 'oddsgate-ds';

export const StyledLogo = styled.div`
    width: 8rem;

    @media only screen and (min-width: ${variables.responsiveMedia}) {
        width: 15rem;
    }
`;

const Logo = () => {
    const state = useGlobalContext();

    return (
        <StyledLogo>
            <Link href={`/${state.lang}`}>
                <Image
                    src={'/assets/logo.svg'}
                    alt={process.env.NEXT_PUBLIC_SITE_NAME as string}
                    width={217}
                    height={37}
                    priority={true}
                />
            </Link>
        </StyledLogo>
    );
};
export default Logo;
